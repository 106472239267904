<template>
  <div class="card">
    <div class="card-header"><img :src="image" :alt="alt" :title="alt" /></div>
    <div class="card-content">
      <h3>{{ title }}</h3>
      <p>{{ text }}</p>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
  name: "VerticalCard",
  props: {
    title: { type: String, required: true },
    text: { type: String, required: true },
    image: { type: String, required: true },
    alt: { type: String, required: false },
  },
});
</script>

<style scoped lang="scss">
.card {
  margin: 1rem;
  flex-basis: 100%;
  background-color: lighten($base-color, 2%);
}
.card-header {
  width: 100%;
}
.card-content {
  padding: 1rem;
}
img {
  width: 100%;
  aspect-ratio: 1/1.6;
  object-fit: cover;
}
h3 {
  font-size: 1.5rem;
  font-weight: 500;
  margin-top: 0;
  margin-bottom: 0.5rem;
}
</style>
