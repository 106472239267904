import { createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!
  const _component_router_view = _resolveComponent("router-view")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("header", null, [
      _cache[3] || (_cache[3] = _createElementVNode("a", { href: "/" }, [
        _createElementVNode("img", {
          class: "logo",
          src: "/logo_horizontal_serif.png"
        })
      ], -1)),
      _createElementVNode("nav", null, [
        _createVNode(_component_router_link, { to: "/" }, {
          default: _withCtx(() => _cache[0] || (_cache[0] = [
            _createTextVNode("Home")
          ])),
          _: 1
        }),
        _createVNode(_component_router_link, { to: "/contact" }, {
          default: _withCtx(() => _cache[1] || (_cache[1] = [
            _createTextVNode("Contact and Quotes")
          ])),
          _: 1
        }),
        _createVNode(_component_router_link, { to: "/commissions" }, {
          default: _withCtx(() => _cache[2] || (_cache[2] = [
            _createTextVNode("Commissions")
          ])),
          _: 1
        })
      ])
    ]),
    _createElementVNode("main", null, [
      _createVNode(_component_router_view)
    ]),
    _cache[4] || (_cache[4] = _createElementVNode("footer", null, [
      _createElementVNode("div", null, " This site uses no cookies, analytics, or any tracking whatsoever ✨ "),
      _createElementVNode("div", null, [
        _createTextVNode("© 2024, "),
        _createElementVNode("a", { href: "mailto:rob@kirberich.uk" }, "Robert Kirberich")
      ])
    ], -1))
  ], 64))
}