import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "card" }
const _hoisted_2 = { class: "card-header" }
const _hoisted_3 = ["src", "alt", "title"]
const _hoisted_4 = { class: "card-content" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("img", {
        src: _ctx.image,
        alt: _ctx.alt,
        title: _ctx.alt
      }, null, 8, _hoisted_3)
    ]),
    _createElementVNode("div", _hoisted_4, [
      _createElementVNode("h3", null, _toDisplayString(_ctx.title), 1),
      _createElementVNode("p", null, _toDisplayString(_ctx.text), 1)
    ])
  ]))
}