import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "statement-cards" }
const _hoisted_2 = { class: "contact-callout" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_HeroImage = _resolveComponent("HeroImage")!
  const _component_VerticalCard = _resolveComponent("VerticalCard")!
  const _component_HorizontalCard = _resolveComponent("HorizontalCard")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_HeroImage, {
      title: "Kirberich Woodworking",
      subtitle: "Custom furniture from a tiny workshop in Edinburgh",
      image: "/images/desk_handle_detail_wide.jpg"
    }),
    _createElementVNode("div", _hoisted_1, [
      _createVNode(_component_VerticalCard, {
        title: "Handmade Quality",
        text: "I build every piece of furniture myself, prioritising\n      quality over speed, to make things that last.",
        alt: "Vanity - solid oak",
        image: "/images/side_back_square.jpg"
      }),
      _createVNode(_component_VerticalCard, {
        title: "Small or Large, Simple or Complex",
        text: "Anything from simple shelves and plywood\n      desks to complex designs and large solid timber furniture.",
        alt: "Desk and Shelf, welded steel frame and plywood",
        image: "/images/plant_desk.jpg"
      }),
      _createVNode(_component_VerticalCard, {
        title: "Honest Materials",
        text: "I use only quality plywood and sustainable, preferably\n      local, hardwoods like oak and walnut for my furniture.",
        image: "/images/walnut_board.jpg"
      })
    ]),
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_HorizontalCard, { images: ['/images/standing_desk_side_folded_small.jpg'] }, {
        default: _withCtx(() => _cache[0] || (_cache[0] = [
          _createElementVNode("h2", null, "Contact Me", -1),
          _createElementVNode("p", null, " No matter if you know exactly what you like or just have a rough idea - Reach out via instagram, email, or the contact page to discuss your project, big or small. ", -1),
          _createElementVNode("p", null, [
            _createElementVNode("a", { href: "https://instagram.com/kirberich" }, "Instagram"),
            _createTextVNode(" · "),
            _createElementVNode("a", { href: "mailto:rob@kirberich.uk" }, "Email"),
            _createTextVNode(" · "),
            _createElementVNode("a", { href: "/contact" }, "Contact form")
          ], -1)
        ])),
        _: 1
      })
    ])
  ], 64))
}