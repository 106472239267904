<template>
  <div :class="classList(['card'])">
    <div class="card-content" v-if="align == 'right'">
      <slot></slot>
    </div>
    <div class="card-image">
      <span v-if="images.length > 1">
        <carousel :items-to-show="1">
          <slide v-for="image of images" :key="image">
            <img :src="image" :alt="alt" :title="alt" />
          </slide>
          <template #addons>
            <navigation />
            <pagination />
          </template>
        </carousel>
      </span>
      <span v-else>
        <img
          :src="images[0]"
          :alt="alt"
          :title="alt"
          :class="
            orientation === 'portrait'
              ? 'portrait single-image'
              : 'landscape single-image'
          "
        />
      </span>
    </div>
    <div class="card-content" v-if="align == 'left'">
      <slot></slot>
    </div>
  </div>
</template>

<script lang="ts">
import "vue3-carousel/dist/carousel.css";
import { defineComponent, PropType } from "vue";
import { Carousel, Slide, Pagination, Navigation } from "vue3-carousel";

export default defineComponent({
  name: "HorizontalCard",
  components: {
    Carousel,
    Slide,
    Pagination,
    Navigation,
  },
  props: {
    images: { type: Array as PropType<string[]>, required: true },
    alt: { type: String, required: false },
    align: { type: String, default: "left" },
    orientation: { type: String, default: "" },
  },
  methods: {
    classList(classes: string[]) {
      if (this.orientation == "portrait") {
        classes.push("portrait");
      } else if (this.orientation == "landscape") {
        classes.push("landscape");
      } else {
        classes.push("any");
      }

      if (this.align == "left") {
        classes.push("align-left");
      } else {
        classes.push("align-right");
      }

      return classes;
    },
  },
});
</script>

<style lang="scss">
.carousel__prev--disabled,
.carousel__next--disabled {
  display: none;
}
.carousel__track {
  margin: 0;
}
</style>

<style scoped lang="scss">
.card {
  margin: 1rem;
  padding: 2rem;
  background-color: lighten($base-color, 2%);
  max-width: 80rem;
  margin-left: auto;
  margin-right: auto;
}
.card-image {
  display: flex;
  justify-content: center;
}
.card-content {
  height: 100%;
  vertical-align: top;
}

.card.align-left .card-image,
.card.align-right .card-content {
  margin-bottom: 2rem;
}

.card.landscape {
  .card-content {
    padding-bottom: 0;
  }
  .card-image {
    padding-top: 0;
  }
  img {
    aspect-ratio: 4/3;
  }
}

.card.portrait,
.card.any {
  img {
    aspect-ratio: 1/1.3;
  }
}

img {
  // height: 500px;
  max-width: 100%;
  object-fit: cover;
}

.single-image {
  // height: 500px;
}

h2,
:slotted(h2) {
  font-size: 2.5rem;
  font-weight: 500;
  margin: 0;
}

@media only screen and (min-width: 800px) {
  .card.portrait,
  .card.any {
    display: flex;

    .card-content,
    .card-image {
      display: inline-block;
    }

    .single-image {
      width: 100%;
      max-height: 100%;
    }

    &.card.align-left .card-image,
    &.card.align-right .card-content {
      margin-bottom: 0rem;
    }

    &.card.align-left .card-content {
      padding-left: 1rem;
    }
    &.card.align-right .card-content {
      padding-right: 1rem;
    }
  }
}

@media only screen and (min-width: 1000px) {
  .card.landscape {
    display: flex;

    .card-content,
    .card-image {
      display: inline-block;
      width: 50%;
    }

    .single-image {
      max-height: 100%;
    }

    &.card.align-left .card-image,
    &.card.align-right .card-content {
      margin-bottom: 0rem;
    }

    &.card.align-left .card-content {
      padding-left: 1rem;
    }
    &.card.align-right .card-content {
      padding-right: 1rem;
    }
  }
}
</style>
